.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.main h3 {
    margin: 0;
    font-size: 16px;
    padding: 16px;
}


.linksContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.container{
    display: flex;
}

.main h1 {
    font-size: 18px;
    margin: 0;
}

.links {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 450px;
    margin: 0 10px;
}

.titles {
    color: black;
    width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #a9a8a8;
}

.firstImg {
    width: 50%;
}

@media (max-width: 850px) {
    .titles {
    }
}

@media (max-width: 1100px) {
    .main {
        justify-content: center;
    }
}

@media (min-width: 1900px) {
    .main {
        justify-content: center;
    }
}

@media (max-width: 550px) {
    .titles h3 {
        font-size: 0.8em;
    }

    .titles h1 {
        font-size: 1em;
    }
}

@media (max-width: 700px) {
    .titles {
        width: calc(100% - 20px);
    }

    .links {
        width: calc(100% - 20px);
    }
}



